

import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ElTable } from 'element-ui/types/table'

@Component({ name: 'ExwarehouseAdd' })
export default class extends Vue {
  @Ref('table') readonly table!: ElTable
  @Ref('form') readonly form!: ElForm
  private formData = {
    projectId: '',
    warehouseId: '',
    checkoutDate: '',
    checkoutUser: '',
    checkoutExplain: '',
    detailedList: []
  }

  private tableData = []
  private loading = false

  private searchForm = {
    materialName: '',
    supplierId: '',
    materialTypeId: '',
    stock: '1'
  }

  private dialogVisible = false
  private page = 1
  private total = 0
  private size = 10

  private selectItems = [] // 选中的临时物料
  private warehouseData = [] // 仓库
  private projectUserList = []
  private supplierData = [] // 供应商类型
  private materialTypeData = []

  private rules = {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'blur' }
    ],
    warehouseId: [
      { required: true, message: '请选择出库仓库', trigger: 'blur' }
    ],
    checkoutDate: [
      { required: true, message: '请选择出库日期', trigger: 'blur' }
    ],
    checkoutUser: [
      { required: true, message: '请选择领用人', trigger: 'blur' }
    ],
    detailedList: [
      { required: true, type: 'array', message: '请添加出库物料明细', trigger: 'blur' }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  @Watch('formData.projectId')
  loadProjectUserList () {
    // 重置领用人
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: this.formData.projectId
    }).then(res => {
      this.projectUserList = res || []
      this.formData.checkoutUser = ''
    })

    // 重置仓库及出库物料
    this.$axios.get(this.$apis.material.selectYhWarehouseByList, {
      projectId: this.formData.projectId
    }).then(res => {
      this.warehouseData = res.list
      this.formData.warehouseId = ''
    })
    this.formData.detailedList = []
  }

  created () {
    this.loadMaterialType()
    this.loadSupplierType() // 加载供应商
  }

  getRowKeys (row: any) {
    return row.materialId
  }

  loadMaterialType () {
    this.loading = true
    this.$axios.get(this.$apis.material.selectYhMaterialTypeByPage).then(res => {
      this.materialTypeData = res.list
    })
  }

  // 加载供应商
  loadSupplierType () {
    this.$axios.get(this.$apis.material.selectYhSupplierByPage).then(res => {
      this.supplierData = res.list
    })
  }

  deleteDetailedList (index: number) {
    this.formData.detailedList.splice(index, 1)
  }

  loadmaterialList () {
    if (!this.formData.warehouseId) {
      this.$message({ type: 'warning', message: '请选择出库仓库' })
    } else {
      this.page = 1
      this.dialogVisible = true
      this.getMaterialData()
      this.$nextTick(() => {
        this.table.clearSelection()
        this.formData.detailedList.forEach(item => {
          this.table.toggleRowSelection(item, true)
        })
      })
    }
  }

  getMaterialData () {
    // this.tableData.loading = true
    this.$axios.get(this.$apis.material.selectYhMaterialByPage, {
      ...this.searchForm,
      page: this.page,
      size: this.size,
      warehouseId: this.formData.warehouseId
    }).then(res => {
      // console.log('res', res)
      this.total = res.total || 0
      this.tableData = res.list || []
    })
  }

  selectionLineChangeHandle (val: any) {
    // console.log('val', val)
    this.selectItems = val
  }

  // 选择工单
  confirm () {
    let arr = []
    arr = this.selectItems.filter((item: any) => {
      return this.formData.detailedList.every((k: any) => {
        return item.materialId !== k.materialId
      })
    })
    this.formData.detailedList = [...this.formData.detailedList, ...arr]
    this.dialogVisible = false
  }

  onSearchWorkOrder () {
    this.page = 1
    this.getMaterialData()
  }

  submit () {
    const form = JSON.parse(JSON.stringify(this.formData)) as any
    // console.log('form', form)
    this.form.validate(valid => {
      if (valid) {
        for (let i = 0; i < form.detailedList.length; i++) {
          const item = {
            materialId: form.detailedList[i].materialId,
            operationStock: form.detailedList[i].operationStock,
            bak: form.detailedList[i].bak,
            stock: form.detailedList[i].stock
          }
          if (!item.operationStock || ((!Number(item.operationStock) || item.operationStock <= 0) || item.operationStock > item.stock)) {
            this.$message({ type: 'warning', message: '出库数量为正整数且小于剩余库存数量' })
            return
          }
          delete item.stock
          form.detailedList[i] = item
        }
        this.$axios.post(this.$apis.material.insertYhCheckout, form).then(() => {
          this.$router.back()
        })
      }
    })
  }

  selectable (row: any) {
    return !this.formData.detailedList.some((item: any) => item.materialId === row.materialId)
  }
}
